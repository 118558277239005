//
// Transitions
// --------------------------------------------------

%default-transition,
%dft {
	transition-property: background-color, color, border-color;
	transition-duration: .25s;
	transition-timing-function: linear;
}

%trans-all {
	transition-property: all;
	transition-duration: .75s;
	transition-timing-function: linear;
}

.lazyload {}

.fade-lazyload {
	transition-property: opacity;
	transition-duration: .25s;
	transition-timing-function: easeOutCubic;
	opacity: 0;

	&.lazyloaded {
		opacity: 1;
	}
}
