//
// Social
// --------------------------------------------------

.social-media {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: row nowrap;
  height: 100%;

  &__link {
    // margin-right: rem-calc(10);
    background-color: palette(brand);
    border-color: #348ab6;
    border-style: solid;
    border-width: 0 1px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    transform: translate(0, -100%);
    transition: transform .8s cubic-bezier(.22,.61,.36,1), opacity .4s cubic-bezier(.22,.61,.36,1);

    @for $i from 1 through 7 {
      &:nth-of-type(#{$i}) {
        transition-delay: #{.1 * $i}s;
      }
    }

    &:last-child {
      border-right: 0;
    }

    &::before {
      @include overlay(0, linear-gradient(to bottom, palette(brand), palette(brand) 29%, palette(brand, secondary)));
      transition: opacity .4s ease;
      z-index: 2;
    }

    svg {
      fill: palette(white);
      max-height: rem-calc(50);
      max-width:  rem-calc(50);
      transition: all .4s ease;
      will-change: transform;
      z-index: 3;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      svg {
        transform: scale(1.2, 1.2);
      }
    }
  }

  @include at(pp) {
    flex-direction: column;

    &__link {
      border-width: 0 0 1px;

      &::before {
        background: linear-gradient(to left, palette(brand), palette(brand) 29%, palette(brand, secondary))
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @include at(pt) {
    flex-direction: column;

    &__link {
      border-width: 0 0 1px;

      &::before {
        background: linear-gradient(to left, palette(brand), palette(brand) 29%, palette(brand, secondary))
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.overlay--social-media {
  position: fixed;
  top:  0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width:  100%;
  z-index: 1001;

  .overlay__close {
    cursor: pointer;
    position: absolute;
    top:    rem-calc(40);
    right:  rem-calc(40);
    height: rem-calc(18);
    width:  rem-calc(18);
    line-height: 0;
    transition: opacity .4s ease;
    transition-delay: 0s;
    z-index: 5;
    opacity: 0;

    svg {
      fill: palette(white);
      transition: fill .3s cubic-bezier(.22,.61,.36,1);
      height: 100%;
      width:  100%;
    }

    &:hover {
      svg {
        fill: palette(brand, secondary);
      }
    }
  }

  .social-media {
    &__link {
      opacity: 0;
    }
  }

  &.is-expanded {
    pointer-events: auto;

    .social-media {
      &__link {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .overlay__close {
      opacity: 1;
      transition-delay: 1.4s;
    }
  }
}