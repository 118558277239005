//
// Footer
// --------------------------------------------------

.l-region--footer {
  background: {
    color: palette(brand, dark);
    size: cover;
  }
  color: palette(white);
  font-size: rem-calc($font-size--p);
  position: relative;
  overflow: hidden;

  &::before {
    display: none;
    content: url('../images/logo.svg');
    opacity: .03;
    position: absolute;
    bottom: -5%;
    right: -2%;
    width:   80%;
    z-index: 1;
    transition: bottom .3s ease;
    pointer-events: none;

    @include from(pt) {
      display: block;
    }

    @include from(d) {
      top: 32%;
      bottom: auto;
    }
  }

  .footer {
    &__form {
      @include add-section-vertical-spacing('padding', $spacing-unit--vert / 2, 'top');
      @include add-horizontal-spacing;
      @include add-max-width;
      margin-left:  auto;
      margin-right: auto;
      position: relative;
      z-index: 2;

      p {
        margin-bottom: rem-calc(10);
        text-align: center;
      }
    }

    &__first {
      @include add-section-vertical-spacing('padding', $spacing-unit--vert / 2);
      @include add-horizontal-spacing;
      @include add-max-width(1000);
      margin-right: auto;
      margin-left:  auto;
      position: relative;
      z-index: 2;

      &__col {
        border-bottom: palette(grey, dark) 1px solid;
        padding-bottom: rem-calc(30);
        margin-bottom: rem-calc(30);

        &-3 {
          border: 0;
          margin-bottom: 0;
          padding-bottom: 0;

          span {
            font-family: helvetica, arial;
          }
        }
      }

      @include from(lp) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        &__col {
          flex: 0 0 50%;

          &-1 {
            padding-right: rem-calc(20);
          }

          &-2 {
            padding-left: rem-calc(20);
          }

          &-3 {
            flex: 0 0 100%;
          }
        }
      }

      @include from(lt) {
        flex-wrap: nowrap;

        &__col {
          border-bottom: 0;
          border-right: palette(grey, dark) 1px solid;
          flex: 0 0 percentage(1/3);
          margin: 0;
          padding: rem-calc(0 40);

          &-1 {
            padding-left: 0;
          }

          &-3 {
            border: 0;
            padding-right: 0;
          }
        }
      }

      @include from(d) {

      }
    }

    &__links {
      a {
        display: flex;
        font-weight: $font-weight--body;

        &::before {
          content: url(../images/icons/chevron--tight.svg);
          margin-top: -1px;
          margin-right: 6px;
          width: 6px;

          @include from(pt) {
            margin-right: rem-calc(6);
            width: rem-calc(6);
          }
        }
      }
    }

    &__second {
      border-top: palette(grey, dark) 1px solid;
      font-size: rem-calc(13);
      padding-top:    rem-calc(20);
      padding-bottom: rem-calc(20);
      text-align: center;

      &__inner {
        @include add-horizontal-spacing;
        @include add-max-width(1000);
        margin-right: auto;
        margin-left:  auto;
      }

      a {
        font-weight: $font-weight--body;
      }

      &__col-1 {
        margin-bottom: rem-calc(10);
      }

      @include from(lp) {
        &__inner {
          display: flex;
          justify-content: center;
        }

        &__col-1 {
          margin: rem-calc(0 40 0 0);
        }
      }
    }
  }

  h4 {
    color: palette(white);
    font-size: rem-calc($font-size--p);
    text-transform: none;
    font-weight: $font-weight--bold;
    letter-spacing: normal;
    margin-bottom: rem-calc(10);
  }

  a {
    color: palette(white);
  }

  svg {
    fill: palette(white);
  }
}
