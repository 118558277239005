//
// Leap Layout
// --------------------------------------------------

.wrap {
  @include add-horizontal-spacing;
  @include add-max-width($max-width--wide);
  @include pie-clearfix;
  margin-right: auto;
  margin-left:  auto;
}

.small-wrap {
  @include add-horizontal-spacing;
  @include add-max-width($max-width--narrow);
  margin-right: auto;
  margin-left:  auto;
}

.lock,
.overly-lock {
  overflow: hidden;
}

.l-page {
  min-height: 100%;
  position: relative;
  z-index: 3;
}

.sidebar-primary {
  // @TODO: Change to flexbox
  @include from(pt) {
    main.main {
      @include grid-span(1, 1, $grid: 3 1 1, $gutter: .1);
    }

    aside.sidebar {
      @include grid-span(1, 3, $grid: 3 1 1, $gutter: .1);
    }
  }

  main.main {
    margin-bottom: rem-calc(60);
  }
}
