//
// Team Members
// --------------------------------------------------

.team-members {
  padding: 0;

  &__inner {
    @include add-horizontal-spacing;
  }

  &__group {
    margin-bottom: rem-calc(30);

    @include from(pt) {
      margin-bottom: rem-calc(40);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      margin-bottom: rem-calc(30);

      @include from(pt) {
        margin-bottom: rem-calc(40);
      }
    }

    &__inner {
      display: flex;
      flex-flow: row wrap;
      margin: rem-calc(0 -10);

      @include from(pt) {
        margin: rem-calc(0 -20);
      }
    }
  }
}

.team-member {
  overflow: hidden;
  text-align: center;
  margin-bottom: rem-calc(30);
  padding: rem-calc(0 10);
  flex: 0 0 percentage(1/2);

  .large & {
    flex: 0 0 100%;
  }

  @include from(lp) {
    flex: 0 0 percentage(1/3);
  }

  @include from(pt) {
    margin-bottom: rem-calc(40);
    padding: rem-calc(0 20);
  }

  @include from(lt) {
    flex: 0 0 percentage(1/4);

    .large & {
      flex: 0 0 50%;
    }
  }

  &__inner {
    display: block;
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: rem-calc(20);
    padding-bottom: 133%;

    .bg-img {
      transition: all .4s ease;
      will-change: transform;
    }

    &::before {
      @include overlay(0, palette(brand, accent));
      transition: opacity .4s ease;
      z-index: 2;
    }
  }

  &__name {
    line-height: 1.2;
    margin-bottom: rem-calc(5);

    @include at(pp) {
      font-size: 18px;
    }
  }

  &__title {
    line-height: 1.2;

    @include at(pp) {
      font-size: 14px;
    }
  }

  &__content {
    // @TODO: Replace with placeholder
    @extend .visible-off-canvas;
  }

  &__inner:hover &__image {
    .bg-img {
      transform: scale(1.03, 1.03);
    }

    &::before {
      opacity: .5;
    }
  }

  &--loader {
    background-color: palette(brand);
    color: palette(white);

    .team-member__header {
      margin-bottom: rem-calc(20);
    }

    .team-member__name {
      color: palette(white);
      font-size: 36px;

      @include from(pt) {
        font-size: rem-calc(42);
      }
    }

    .team-member__title {
      font-size: 20px;

      @include from(pt) {
        font-size: rem-calc(30);
      }
    }

    a {
      color: palette(white);
    }

    .loader__close {
      color: palette(white);
    }

    .loader__content {
      padding: rem-calc(0 0 20);

      .team-member__header,
      .team-member__content {
        padding-right: rem-calc(20);
        padding-left:  rem-calc(20);

        @include from(lt) {
          padding-right: rem-calc(50);
          padding-left:  rem-calc(50);
        }
      }

      @include from(lt) {
        padding: rem-calc(0 0 40);

        .team-member__image {
          margin-bottom: rem-calc(40);
        }
      }
    }
  }
}
