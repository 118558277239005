%list-container {
  overflow: hidden;
  padding: rem-calc(10 0);
}

.entry-content,
.page__content,
.component__content,
.constrained {
  @include add-max-width;
  margin-right: auto;
  margin-left:  auto;
  position: relative;
  z-index: 2;
}

.constrained {
  @include add-max-width($max-width--base, false);
}

.entry-content,
.page__content,
.component__content {
  @include add-horizontal-spacing;
  @include pie-clearfix;

  &.narrow {
    @include add-max-width($max-width--narrow);
  }

  &.wide {
    @include add-max-width($max-width--wide);
  }

  &.xwide {
    @include add-max-width($max-width--xwide);
  }

  &.full-width {
    max-width: none;
  }

  .link-handler {
    margin-top: rem-calc(40);
    text-align: center;

    .btn {
      &:not(:first-child) {
        margin-left: rem-calc(15);
      }
    }

    .standard-text & {
      text-align: left;
    }
  }
}

.entry-content{
	@media (max-width:660px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.content-block {
  @include add-section-vertical-spacing;
  position: relative;
  overflow: hidden;

  .not-front &:not(:first-of-type) {
    .heading {
      font-size: rem-calc(25);
    }
  }

  &.remove-padding {
    @include remove-section-vertical-spacing-top;
  }

  &.background-color--light.padding-collapse + .background-color--light.padding-collapse,
  &.background-color--dark.padding-collapse + .background-color--dark.padding-collapse {
    @include remove-section-vertical-spacing-top;
  }

  &.two-tile,
  &.slider,
  &.image-grid,
  &.gallery,
  &.map {
    padding-top:    0;
    padding-bottom: 0;
  }

  &.is-aligned-left {
    text-align: left;
  }

  &.is-aligned-center {
    text-align: center;
  }

  &.is-aligned-right {
    text-align: right;
  }
}

.main,
.sidebar {
  .home &,
  .page & {
    margin-top:    0;
    margin-bottom: 0;
  }
}

section {
  &.background-color--dark,
  &.dark {
    background-color: #f7f8f8;
  }

  .entry-content {
    position: relative;
  }
}

body {
  transition: opacity .6s $ease-out-cubic;

  &::before {
    content: '';
    position: fixed;
    top:  0;
    left: 0;
    height: 100%;
    width:  100%;
    background: palette(white, dark);
    pointer-events: none;
    opacity: 1;
    z-index: 1000;
  }

  &.pace-running {
    pointer-events: none;
    overflow: hidden;
  }

  &.pace-done {
    &::before {
      opacity: 0;
    }
  }
}

.l-page {
  opacity: 0;
  transition: opacity .6s $ease-out-cubic;

  .pace-done & {
    opacity: 1;
  }
}

.loading {
  position: fixed;
  top:  0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 1;
  height: 100%;
  width:  100%;
  z-index: 10000;

  .icon-loading {
    max-height: none;
    height: rem-calc(246);
    width: rem-calc(483);
  }

  .pace-done & {
    opacity: 0;
  }
}