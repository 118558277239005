.featured-news {
  background-color: palette(brand, accent);

  .bg-img {
    &::after {
      @include overlay(.3, palette(brand, accent));
    }

    @include to(lp) {
      display: none;
    }
  }

  .heading {
    @include add-horizontal-spacing;
    color: palette(white);
    margin-top:    0;
    margin-bottom: rem-calc(50);
    position: relative;
    text-align: center;
  }

  .post-list {
    margin-bottom: rem-calc(-20);
    padding-top:    0;
    padding-bottom: 0;
  }

  .listing {
    &__inner {
      background-color: rgba(palette(black), .6);
    }

    &:hover {
      .listing__inner {
        background-color: rgba(palette(brand, dark), .85);
      }
    }

    @include from(lp) {
      flex: percentage(1/2);
      width: auto;
    }

    @include from(lt) {
      flex: percentage(1/3);
      width: auto;
    }
  }
}