//
// Gallery
// --------------------------------------------------

.gallery {
  .heading {
    color: palette(brand);
    margin: 0;
    padding: rem-calc(0 0 40);
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item {
    display: block;
    flex: 0 1 50%;
    outline: 0;
    overflow: hidden;
    position: relative;

    &.is-wide {
      flex: 0 1 100%;
    }

    &.hide {
      position: absolute;
      left: -9999em;
    }

    img {
      display: block;
      transition: all .4s ease;
      will-change: transform;
      max-width: none;
      width:     100.5%;
    }

    &.is-last {
      color: #FFF;
      font-size: rem-calc(48);
      position: relative;

      span {
        height: 100%;
        width:  100%;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
      }

      &::after {
        background-color: rgba(palette(black), .7);
        content: '';
        position: absolute;
        top:  0;
        left: 0;
        height: 100%;
        width:  100%;
        z-index: 1;
      }
    }

    &::before {
      @include overlay(0, palette(brand, accent));
      transition: opacity .4s ease;
      z-index: 2;
    }

    @include from(lp) {
      flex: 0 1 25%;

      &.is-wide {
        flex: 0 1 50%;
      }
    }

    &:hover {
      img {
        transform: scale(1.03, 1.03);
      }

      &::before {
        opacity: .5;
      }
    }
  }
}


// mfp

.mfp-fade.mfp-wrap.mfp-ready {
  &.next {
    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(-20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform: scale(1) translateX(20%);
        transition: all 0s linear;
      }
    }
  }

  &.prev {
    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform: scale(1) translateX(-20%);
        transition: all 0s linear;
      }
    }
  }
}
