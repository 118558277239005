//
// Forms
// --------------------------------------------------

input[type='tel'],
input[type='text'],
input[type='email'],
input[type='number'],
input[type='search'],
textarea,
select {
  appearance: none;
  // @TODO: Replace colors with variables?
  background-color: #f1f2f3;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: $color--text;
  display: block;
  padding: rem-calc(15 20);
  font-family: $font-family--heading;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  vertical-align: middle;
  resize: none;
  outline: 0;
  width: 100%;

  &::placeholder {
    color: #6e7173;
  }
}

select {
  background: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==") no-repeat calc(100% - 10px) center #f1f2f3;
  background-size: rem-calc(10);

  option {
    font-size: 16px;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  color: $color--text;
  height: rem-calc(130);

  .gform_wrapper & {
    color: $color--text;
  }
}

input[type='submit'] {
  @include button;
  background-color: palette(brand, accent);
  border-radius: 10px;
  outline: none;

  &:hover {
    background-color: palette(brand);
  }
}

// Gravity Forms

.gform_validation_container {
  display: none;
}

.gfield.half-col.first,
.name_first,
.ginput_left {
  margin-bottom: rem-calc(10);
}

@include from(lp) {
  .gfield.half-col,
  .name_first,
  .name_last,
  .ginput_left,
  .ginput_right {
    // @TODO: Update to use flexbox?
    @include pie-clearfix;
    float: left;
    width: calc(50% - #{rem-calc(5)});
  }


  .gfield.half-col.first,
  .name_first,
  .ginput_left {
    margin-right: rem-calc(10);
    margin-bottom: 0;
  }
}

.ginput_complex {
  @include pie-clearfix;

  span {
    display: block;
    // margin-top: rem-calc(10px);
  }

  @include from(pt) {
    .ginput_full {
      clear: both;
    }
    // .ginput_left {
    //   float: left;
    //   width: 48%;
    // }
    // .ginput_right {
    //   float: right;
    //   width: 48%;
    // }
  }
}

.gclear {
  clear: both;
}

li.gfield {
  border: 0;
  margin: rem-calc(0 0 10);
  padding: 0;
  list-style-type: none;
  list-style-image: none;
  width: 100%;

  li {
    list-style-type: none;
    list-style-image: none;
  }
}

.gform_wrapper {
  .gform_fields {
    display: flex;
    flex-flow: row wrap;
  }

  .gform_title {
    margin-bottom: rem-calc(30);
  }

  .gsection {
    border-top: $color--border 1px solid;
    border-bottom: 0;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
    padding-top: rem-calc(30);

    &:first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }

  .gform_heading {
    margin-bottom: rem-calc(20);

    + .gform_body .gsection:first-child {
      border-top: $color--border 1px solid;
      padding-top: rem-calc(30);
    }
  }

  .gsection_title {
    @extend h6;
  }

  .ginput_complex label,
  .gfield_label {
    display: none;
    font-family: $font-family--heading;
    font-size: rem-calc(15);
    font-weight: 700;
    margin-bottom: rem-calc(5);
    position: relative;

    &::after {
      content: ':';
    }

  }

  .gfield_contains_required {
    .ginput_complex label,
    .gfield_label {
      .gfield_required {
        display: none;
      }

      &::before {
        content: '*';
        color: palette(brand, secondary);
        margin-left: 3px;
        position: absolute;
        top: 0;
        left: 100%;
      }
    }

    .ginput_container_address {
      > span:not(:first-child) {
        label {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .show-label,
  #gform_2,
  #gform_4,
  #gform_5 {
    .ginput_complex label,
    .gfield_label {
      display: inline-block;
    }

    .gfield_label_before_complex {
      display: none;
    }
  }

  .validation_message,
  .validation_error {
    border-radius: 10px;
    color: palette(white);
    font-size: rem-calc(13);
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
  }

  .validation_error {
    background-color: palette(brand, secondary);
    margin-bottom: rem-calc(10);
    padding: rem-calc(15 20);
  }

  .validation_message {
    background-color: palette(brand, dark);
    padding: rem-calc(5 20);
    margin-top: rem-calc(10);
  }

  .ginput_container_fileupload .validation_message {
    display: none;
  }

  form {
    .instruction {
      display: none;
    }

    ul {
      margin:  0;
      padding: 0;
    }

    .gfield_radio,
    .gfield_checkbox {
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      input {
        margin-right: rem-calc(10);
      }

      @include from(lp) {
        padding-left: rem-calc(30);
      }
    }

    input[type='submit'] {
      @include button;
      background-color: palette(brand, accent);
      border-radius: 10px;

      &:hover {
        background-color: palette(brand);
      }
    }

    .ginput_card_security_code {
      margin-top: rem-calc(10) !important;
    }

    .clear-multi {
      > div {
        display: inline-block;
        margin-right: rem-calc(10);

        &:last-child {
          margin-right: 0;
        }
      }

      input, select {
        margin-top: 0;
      }

      .ginput_container {
        vertical-align: middle;
      }
    }

    .gform_footer {
      margin-top: rem-calc(10);
    }
  }
}

.gform_confirmation_wrapper {
  line-height: 1.5;
  text-align: center;

  footer & {
    color: palette(brand, secondary);
    font-weight: bold;
  }
}

// Datepicker
.ui-datepicker {
  background-color: palette(white);
  border: 1px solid palette(grey, light);
  display: none;
  padding: rem-calc(15 25 25);

  select {
    background-color: palette(grey, lighter);
    border: 0;
    border-radius: rem-calc(8);
    margin-top:    rem-calc(5);
    margin-bottom: rem-calc(5);
    width: 48%;

    &:first-child {
      margin-right: 4%;
    }
  }

  table {
    border: 1px solid palette(grey, lighter);
  }

  td,
  th {
    border: 1px solid palette(grey, lighter);
    text-align: center;
    width: rem-calc(40);

    a {
      display: inline-block;
      width: 100%;
    }
  }

  td {
    &:hover,
    &.ui-datepicker-current-day {
      a {
        color: palette(white);
        background-color: palette(brand);
      }
    }
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: $font-weight--regular;
    text-transform: uppercase;
  }

  .ui-datepicker-prev {
    text-align: left;
    width: 50%;
  }

  .ui-datepicker-next {
    text-align: right;
    width: 50%;
  }

  .ui-datepicker-header {
    margin-bottom: rem-calc(10);
  }

  .ui-datepicker-today {
    background-color: palette(grey, lighter);
  }
}

.gform_ajax_spinner {
  display: none !important;
}

#gform_2,
#gform_4,
#gform_5 {
  &, p, ul {
    font-size: rem-calc(15);
  }
}

#field_2_15,
#field_2_16 {
  // float: left;
  width: calc(50% - #{rem-calc(5)});
}

#field_2_15 {
  margin-right: rem-calc(10);
}

#gform_3 {
  .gform_body {
    flex-grow: 1;
  }

  .gfield {
    flex-grow: 1;
    width: auto;

    input[type='email'],
    input[type='number'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    select,
    textarea {
      background-color: palette(grey, light);
      line-height: rem-calc(20);
      padding: rem-calc(10 20);
    }
  }

  .gform_footer {
    margin: 0;
  }

  input[type='submit'] {
    padding-top:    rem-calc(10);
    padding-bottom: rem-calc(10);
    line-height: rem-calc(20);
  }

  .validation_error {
    padding-top:    rem-calc(10);
    padding-bottom: rem-calc(10);

    @include from(lp) {
      border-radius: 0 0 10px 10px;
      position: absolute;
      top:  0;
      left: 0;
      width: 100%;
    }

    @include at(lp) {
      border-radius: 0;
      line-height: .5;
    }
  }

  .validation_message {
    background: palette(brand, secondary);
    border-radius: 3px;
    overflow: hidden;
    padding: 0;
    text-indent: -9999px;
    position: absolute;
    margin: 0;
    top:   0;
    left:  -.8rem;
    height: 100%;
    width: .3rem;

    @include from(lp) {
      left:  1rem;
      height: 1.3rem;
    }
  }

  @include from(lp) {
    display: flex;

    .gform_fields {
      display: flex;
    }

    .gfield {
      margin: 0;

      input[type='email'],
      input[type='number'],
      input[type='search'],
      input[type='tel'],
      input[type='text'],
      select,
      textarea {
        border-radius: 0;
      }

      &:first-child {
        max-width: rem-calc(200);

        input[type='email'],
        input[type='number'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        select,
        textarea {
          border-radius: 10px 0 0 10px;
        }
      }

      &:not(:last-child) {
        border-right: #b7b7b7 1px solid;
      }
    }

    input[type='submit'] {
      border-radius: 0 10px 10px 0;
    }
  }

  #field_3_3 {
    line-height: 1.3;
    margin-top: rem-calc(10);
    position: relative;
  }
}

@include from(lp) {
  #field_2_2,
  #field_4_3,
  #field_5_4 {
    // float: left;
    margin-right: rem-calc(10);
    width: calc(65% - #{rem-calc(5)});
  }

  #field_2_3,
  #field_4_4,
  #field_5_5 {
    // float: left;
    width: calc(35% - #{rem-calc(5)});
  }

  #input_2_12,
  #input_5_9 {
    columns: 2;
  }

  #field_4_6,
  #field_4_7,
  #field_5_11,
  #field_5_12,
  #field_1_3,
  #field_1_4 {
    width: calc(50% - #{rem-calc(5)});
  }

  #field_4_6,
  #field_5_11,
  #field_1_3 {
    margin-right: rem-calc(10);
  }

  #field_4_11 {
    width: calc(50% - #{rem-calc(5)});
  }
}
