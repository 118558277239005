//
// Mobile Navigation
// --------------------------------------------------

.nav--mobile {
  margin:  0;
  padding: 0;


  .nav-brand {
    float: left;
    line-height: 0;
    width: 68px;
  }

  .icon--search {
    float: left;
    margin-left: rem-calc(30);
    stroke: white;
    width: rem-calc(20);
  }

  .hamburger-inner {
    &, &::before, &::after {
      background-color: palette(white);
    }
  }

  ul {
    margin:  0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  &--primary {
    @include add-horizontal-spacing;
    background: palette(brand);
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top:   0;
    right: 0;
    -webkit-overflow-scrolling: touch;
    padding-top:    rem-calc(30);
    padding-bottom: rem-calc(40);
    transform: translateX(100%);
    transition: transform .4s $ease-out-cubic;
    width:  100%;
    height: 100%;
    z-index: 1001;

    @include from(lp) {
      width: 60%;
    }

    @include from(pt) {
      padding-top: rem-calc(60);
    }

    @include from(lt) {
      width: 440px;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateZ(0);
      transition: transform .3s, opacity .3s;
      will-change: transform, opacity;
      white-space: nowrap;
    }

    .no-js & {
      position: relative;
      height: auto;
      width: 100%;
      overflow: visible;
      visibility: visible;
      z-index: 2;
    }
  }

  &__contact {
    padding-top: rem-calc(30);

    .contact-info {
      text-align: left;

      h4 {
        font-size: rem-calc(18);
        letter-spacing: 0;
      }

      > ul {
        margin-bottom: 0;

        li {
          padding: rem-calc(10 0);
        }
      }
    }
  }

  &__buttons {
    padding-top: rem-calc(40);
    text-align: center;

    .button {
      margin-bottom: rem-calc(10);
      width: 90%;
    }
  }

  &__search {
    padding-top:    rem-calc(30);
    padding-bottom: rem-calc(30);
    width: 100%;

    input[type='search'] {
      outline: 0;
      width: 75%;

      @include placeholder {
        color: #999;
      }
    }

    button[type='submit'] {
      background-color: palette(brand);
      color: #fff;
      font-family: $font-family--heading;
      font-size: rem-calc(15);
      font-weight: 700;
      text-transform: uppercase;
    }

    .input-group {
      position: relative;

      .input-group-btn {
        position: absolute;
        top:    0;
        right:  0;
        bottom: 0;
        width: 25%;
      }
    }
  }

  &__social {
    margin-top: rem-calc(20);

    a {
      @extend %default-transition;
      color: palette(brand);
      border: 0;
      float: left;
      font-size: rem-calc(18);
      margin: rem-calc(0 0 0 10);
      padding: 0;
      width: rem-calc(20);

      &:hover {
        color: palette(brand);
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .nav__menu--primary {
    margin-top: rem-calc(25);

    .menu {
      &__back {
        left: 60%;
        margin-bottom: 11px;

        @include from(pt) {
          left: 65%;
          margin-bottom: 12px;
        }
      }
    }
  }

  .nav__menu--secondary {
    @include negate-horizontal-spacing;
    @include add-horizontal-spacing;
    padding-top:    rem-calc(40);
    padding-bottom: rem-calc(40);
    background: palette(brand, accent);

    .menu {
      &__wrap {
        margin-top:    rem-calc(-15);
        margin-bottom: rem-calc(-15);
      }
    }
  }
}

#mobile-footer {
  display: block;
  padding-top: rem-calc(25);

  .nav__menu {
    .menu {
      &__wrap {
        position: static;
      }

      &__level {
        position: static;
        visibility: visible;
      }
    }
  }
}

// Pretty facking menu
// @TODO: Update class structure to indicate mobile variant
.menu {
  // background: palette(white);
  position: relative;
  right: 0;
  z-index: 20000;

  &__wrap {
    position: absolute;
    top:    0;
    bottom: 0;
    width: 100%;
  }

    &__level {
      background: none;
      position: absolute;
      top:  0;
      left: 0;
      overflow: hidden;
      list-style-type: none;
      height: auto;
      visibility: hidden;
      margin:  0;
      padding: 0;
      width: 100%;

      &--current {
        visibility: visible;
      }
    }

      &__item {
        display: block;
        margin: 0;

        .animate-outToRight & {
          animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-outToLeft & {
          animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-inFromLeft & {
          animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-inFromRight & {
          animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }

        &:last-child {
          .menu__link {
            border-bottom: 0;
          }
        }
      }

        &__link {
          display: block;
          color: palette(white);
          border-bottom: 1px solid palette(grey, light);
          position: relative;
          padding: rem-calc(15 0);
          font-size: rem-calc(18);
          font-weight: $font-weight--bold;
          text-transform: none;
          transition: color 0.1s;

          &[data-submenu]::after {
            @extend %right;
            color: palette(white);
            position: absolute;
            top:  50%;
            right: 0;
            line-height: 1em;;
            font-size: rem-calc(24);
            margin-top: rem-calc(-12);
            padding: 0;
            @include icon;
          }

          &:hover,
          &[data-submenu]:hover::after {
            color: palette(white);
          }
        }

  &__back {
    background: none;
    cursor: pointer;
    border: 0;
    bottom: 100%;
    left: -6px;
    display: block;
    color: palette(white);
    font-size: rem-calc(20);
    font-weight: $font-weight--bold;
    line-height: 1em;
    margin-bottom: rem-calc(0 0 3);
    padding: rem-calc(15 0);
    position: absolute;
    opacity: 1;
    transform: translateX(0px);
    transition: transform 0.25s $ease-out-cubic, opacity 0.25s $ease-out-cubic;
    z-index: 100;

    &:hover,
    &:focus {
      color: palette(white);
      outline: none;
    }

    &.is-hidden {
      pointer-events: none;
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &__breadcrumbs {
    position: absolute;
    bottom: 100%;
    left:     0;
    font-size: rem-calc(10);
    line-height: 1em;
    padding: rem-calc(30 0 0);
    height: rem-calc(40);

    a {
      cursor: pointer;
      display: inline-block;
      color: palette(brand);
      vertical-align: top;
      font-size: rem-calc(10);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--large;
      line-height: 1em;
      text-transform: uppercase;
      margin-right:   rem-calc(3);
      padding-right: rem-calc(13);
      position: relative;

      &,
      &:hover {
        color: palette(brand);
      }

      &:last-child {
        pointer-events: none;
      }

      &:not(:last-child) {
        &::after {
          @extend %right;
          @include icon;
          color: palette(brand);
          display: inline-block;
          line-height: rem-calc(10);
          padding: 0;
          position: absolute;
          top:   1px;
          right: 0;
        }

        &:hover::after {
          color: #33353e;
        }
      }
    }

    .breadcrumb-link {
      opacity: 1;
      transition-property: opacity transform;
      transition-duration: 0.25s;
      transition-timing-function: easeOutCubic;
      transform: translateX(0px);

      &.not-visible {
        opacity: 0;
        transform: translateX(20px);

        &:first-child {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
  }
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}