//
// Slider
// --------------------------------------------------

.content-block.slider {
  img, a {
    display: block;
  }

  img {
    max-width: none;
    width: 100%;
  }
}
