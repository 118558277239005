//
// Loader Overlay
// --------------------------------------------------

.overlay-show {
  @include overlay(0, palette(black), fixed, none);
  transition: opacity .4s $ease-out-cubic;
  z-index: 1000;

  .overlay-visible &,
  &.show {
    pointer-events: all;
    opacity: .6;
  }
}


//
// Loader
// --------------------------------------------------

.loader {
  transform: translateX(101%);
  background-color: palette(white);
  // border-left: 1px solid $color--border;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; // has to be scroll, not auto
  position: fixed;
  top:   0;
  right: 0;
  transition: transform .4s $ease-out-cubic;
  will-change: transform;
  max-width: rem-calc(580);
  height: 100%;
  width:  100%;
  z-index: 1300;

  @include at(lp) {
    width: 60%;
  }

  &.show {
    transform: translateX(0%);
  }

  h2,
  h3 {
    color: palette(brand);
    font-size: rem-calc(30);
  }

  &__content {
    padding: rem-calc(40 20);

    @include from(lt) {
      padding: rem-calc(60 50 40 50);
    }
  }

  &__close {
    background-color: rgba(palette(black), .5);
    color: palette(white);
    position: absolute;
    top:   0;
    right: 0;
    font-size: rem-calc(14);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(57);
    width:  rem-calc(119);
    z-index: 10;

    span {
      margin-right: rem-calc(10);
    }

    svg {
      fill: palette(white);
      height: rem-calc(18);
      width:  rem-calc(18);
    }
  }

  .no-csstransforms & {
    right: -200%;

    &.show {
      right: 0%;
    }
  }

  .img-wrap {
    margin-right:   auto;
    margin-left:    auto;
    padding-bottom: 0%;
    height: rem-calc(300);
    width: rem-calc(300);
  }

  .header {
    margin-top:    rem-calc(30);
    margin-bottom: rem-calc(40);

    .title,
    .sub-title {
      margin-bottom: rem-calc(5);
    }
  }
}

.visible-off-canvas {
  display: none;

  .loader & {
    display: block;
  }
}
