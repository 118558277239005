//
// Standard Text
// --------------------------------------------------

.standard-text {
  &:first-of-type {
    h2.heading {
      margin-bottom: rem-calc(20);
    }
  }
}
