//
// Buttons
// --------------------------------------------------

.button,
.btn {
  @include button;
}

.button-wrap {
  padding-top: 0;
  text-align: center;
}

.buttons {
  .button-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .button,
  .btn {
    flex: 1 0 auto;
    margin-right:  rem-calc(10);
    margin-bottom: rem-calc(10);
    outline: 0;
    max-width: rem-calc(350);

    &:last-of-type {
      margin-right: 0;
    }

    @include to(pt) {
      flex: 1 0 135px;
    }
  }
}
