//
// General
// --------------------------------------------------

* {
  &,
  &::before, &::after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width:  100%;
}

.overlay {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p, li, pre, blockquote, a, time, span, svg, input, address, button {
  opacity: 0;

  .wf-active &,
  .wf-inactive & {
    opacity: 1;
  }
}

body {
  background-color: palette(white);

  &.nav-is-visible {
    overflow: hidden;
  }
}

.vert-align {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width:  100%;
}

.vert-align-handler {
  display: table;
  height: 100%;
  width:  100%;
}

.container-content-height {
  @include add-horizontal-spacing;
  position: absolute;
  top:    0;
  right:  0;
  bottom: 0;
  left:   0;
  text-align: center;
  z-index: 100;

  h2 {
    text-align: center;
  }
}

.background-blue {
  background: palette(brand);
}

.content-color-light {
  * {
    border-color: palette(white);
    color: palette(white);
  }

  .btn {
    border-color: palette(white);
    color: palette(white);

    &:hover {
      background: $color--hover;
      border-color: $color--hover;
    }
  }
}

.bg-img {
  background: {
    position: center center;
    repeat: none;
    size: cover;
  }
  position: absolute;
  top:    0;
  right:  0;
  bottom: 0;
  left:   0;
  height: 100%;
  width:  100%;
  z-index: 0;
}

.img-wrap {
  padding-bottom: 70%;
  position: relative;
  overflow: hidden;
}

.notification-wrap {
  text-align: center;
  position: absolute;
  bottom: 0;
  left:   0;
  width: 100%;
}

.notification {
  display: inline-block;
  background: palette(blue);
  color: palette(white);
  border-radius: 8px 8px 0 0;
  padding: rem-calc(15 0);
  max-width: rem-calc(170);
  width:     100%;
  z-index: 100;
  letter-spacing: $letter-spacing--large;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  font: {
    size: rem-calc(13);
    weight: 700;
  }

  .project-gallery-item &,
  .project-snapshot & {
    background: #444;
    color: palette(white);
    border-radius: 20px;
    padding: rem-calc(13 10);
    position: absolute;
    top:    rem-calc(25);
    right:  auto;
    bottom: auto;
    left:   rem-calc(15);
    max-width: rem-calc(170);
    width:     100%;
    letter-spacing: 0;
    font: {
      size: rem-calc(15);
      weight: 700;
    }
  }
}

#loaded {
  height: 1px;
}
