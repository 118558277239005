//
// Contact page
// --------------------------------------------------

.contact-columns {
  @include add-section-vertical-spacing;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  .contact-col {
    margin-bottom: rem-calc(45);
    width: 100%;

    // @TODO: Change to flexbox
    @include from(pt) {
      margin-bottom: 0;
      text-align: left;
      width: percentage(1/3);

      &:last-child {
        width: percentage(2/3);
      }
    }
  }
}

.contact-form {
  .gform_body {
    margin-top: 0;
  }

  #input_1_1 {
    margin-top: 0;
  }
}

.contact-info {
  svg {
    fill: palette(brand);
    margin-right: rem-calc(10);
    height: rem-calc(20);
    width:  rem-calc(20);
  }

  h4 {
    font-size: rem-calc(20);
    margin-bottom: 0;
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 1.2;
    padding: rem-calc(10 0);

    &:first-child {
      padding-top: 0;
    }
  }

  &__addresses {
    address {
      margin-bottom: rem-calc(20);
    }
  }

  &__email,
  &__phone-number {
    display: block;
    font-weight: $font-weight--body;

    span {
      color: palette(brand);
      font-weight: $font-weight--bold;
    }
  }

  &__hours {
    list-style: none;
    padding: 0;

    li {
      padding: 0;
    }
  }

  &--mobile {
    background-color: palette(brand);
    display: flex;
    position: fixed;
    bottom: 0;
    left:   0;
    width: 100%;
    z-index: 100;

    .header-float & {
      border-bottom: 1px solid palette(grey, light);
      position: fixed;
      transform: translateY(100%);
      transition: transform .4s $ease-out-cubic;

      &.header-up,
      &.header-down {
        transition: transform .4s $ease-out-cubic;
      }

      &.header-up {
        transform: translateY(100%);
      }

      &.header-down {
        transform: translateY(0%);
      }
    }

    @include from(lp) {
      position: relative;
      z-index: 1;
    }

    @include from(pt) {
      display: none;
    }
  }

  &__cta {
    color: palette(white);
    border-bottom: 1px solid palette(white, brand);
    font-size: rem-calc(13);
    letter-spacing: $letter-spacing--medium;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
    width: percentage(1/3);

    &:not(:last-child) {
      border-right: 1px solid palette(white, brand);
    }

    &__content,
    &::before {
      padding: rem-calc(15);
      transition: transform .9s $ease-in-out-back .1s;

      @include from(lp) {
        padding: rem-calc(20);
      }

      @include from(pt) {
        padding: rem-calc(30);
      }
    }

    &__content {
      display: block;
      background: palette(brand);
      padding-bottom: rem-calc(12);
      height: 100%;

      // @TODO: Update big / small classnames
      .big {
        display: none;
      }

      .small {
        display: inline-block;
      }

      @include from(lt) {
        padding: rem-calc(20 10 30);

        .big {
          display: inline-block;
        }

        .small {
          display: none;
        }
      }
    }

    &::before {
      content: attr(data-hover);
      background: palette(brand, two);
      color: palette(white);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-25%);
      position: absolute;
      top:  0;
      left: 0;
      height: 100%;
      width:  100%;
      z-index: -1;
    }

    .icon {
      display: block;
      line-height: 1;
      fill: white;
      margin: 0 auto rem-calc(10);
      height: rem-calc(30);
      width:  rem-calc(30);

      @include from(lp) {
        height: rem-calc(30);
        width:  rem-calc(30);
      }

      @include from(pt) {
        height: rem-calc(40);
        width:  rem-calc(40);
      }
    }

    &:hover,
    &:focus {
      color: #FFF;

      @include from(sd) {
        .contact-info__cta__content {
          transform: translateX(100%);
        }

        &::before {
          transform: translateX(0%);
        }
      }
    }
  }
}
