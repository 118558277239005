//
// Button Mixins
// --------------------------------------------------
//
$button-background-default: palette(brand, accent);
$button-color-default:      palette(white);

$button-font:           $font-family--body;
$button-font-size:      rem-calc(15);
$button-font-weight:    $font-weight--semi-bold;
$button-letter-spacing: normal;
$button-border-radius:  rem-calc(100);
$button-padding:        rem-calc(13 30);



@mixin button($background: $button-background-default, $color: $button-color-default) {
  color: $color;
  background: linear-gradient(to right, palette(brand, accent) 0%, palette(brand, accent) 50%, palette(brand, secondary) 100%);
  background-size: 200%;
  background-position: 0;
  font-family: $button-font;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  letter-spacing: $button-letter-spacing;
  line-height: 1em;
  text-align: center;
  padding: $button-padding;
  position: relative;
  border: 0;
  border-radius: $button-border-radius;

  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  transition-property: background-color, color, border, background-position;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  appearance: none;

  @include from(d) {
    padding: $button-padding;
  }

  &:hover {
    color: palette(white);
    background-position: 99%;
    text-decoration: none;
  }

  &.inverse {
    background: palette(white);
    color: palette(brand, accent);
    
    &:hover {
      background: palette(brand, accent);
      color: palette(white);
    }
  }

  svg {
    fill: #fff;
    left: rem-calc(10);
    margin-right: rem-calc(5);
    height: rem-calc(20);
    width:  rem-calc(20);

    @include from(d) {
      margin-right: rem-calc(10);
    }
  }
}
