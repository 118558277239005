//
// Greedy Nav
// --------------------------------------------------

nav.greedy {
  position: relative;
  display: flex;
  align-items: center;

  button {
    align-self: stretch;
    transition: all .4s ease-out;
    outline: 0;
    border: 0;
    background: transparent;
    position: relative;
    padding: rem-calc(0 20 0 0);

    svg {
      height: rem-calc(20);
      width:  rem-calc(20);
      fill: palette(brand, darker);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.hidden {
      transition: none;
      padding: 0;
      width: 0;
      overflow: hidden;
    }
  }

  ul, li {
    margin:  0;
    padding: 0;
    list-style-type: none;
  }

  ul {
    &.links {
      display: flex;
      justify-content: center;
      flex: 1;
      overflow: hidden;
      font-size: rem-calc(18);

      li {
        flex: none;

        a {
          display: block;
          padding: rem-calc(30 18);
          transition-property: all;
          transition-duration: .44s;
          transition-easing: ease;

          &.active,
          &:hover {
            color: $color--text;
          }
        }
      }
    }

    &.hidden-links {
      position: absolute;
      background: palette(brand);
      top:   100%;
      right: 0;
      padding: 0;
      transform: translateY(0);
      transition: all .35s ease;
      opacity: 1;
      z-index: 10;

      li {
        padding: 0;
      }

      a {
        color: palette(white);
        display: block;
        padding: rem-calc(10 15);
        transition: background-color .3s ease-in-out;

        &:hover {
          background-color: palette(brand, accent);

          &::after {
            display: none;
          }
        }
      }

      &.hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
      }
    }
  }
}

.filter {
  @include add-horizontal-spacing;
  background-color: palette(brand);
  text-align: center;

  &__option {
    color: palette(white);
    display: inline-block;
    font-size: rem-calc(18);
    text-transform: uppercase;
    position: relative;
    margin: 0;
    opacity: .8;

    > span {
      position: relative;
      z-index: 1;
    }

    &,
    &::after {
      transition: opacity .3s ease-in-out, transform .3s ease-in-out;
      transition-delay: .2s;
    }

    &::after {
      content: '';
      background-color: palette(white);
      position: absolute;
      bottom: 0;
      left:   0;
      opacity: 0;
      transform: translateY(100%);
      height: 100%;
      width:  100%;
    }

    &.active,
    &:hover {
      opacity: 1;

      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:hover {
      &,
      &::after {
        transition-delay: 0s;
      }
    }

    &.active {
      &,
      &::after {
        // transition-delay: .2s;
      }

      .filter.is-focussed & {
        color: palette(white);
        transition-delay: 0s;

        &::after {
          transform: translateY(100%);
          transition-delay: 0s;
        }
      }
    }
  }
}
