//
// Block Quote
// --------------------------------------------------

.block-quote {
  font-family: $font-family--heading;
  text-align: left;

  &__inner {
    border-left: rem-calc(10) solid $color--border;
    padding-left: rem-calc(20);
  }

  &__author {
    margin-top: rem-calc(30);
    letter-spacing: $letter-spacing--medium;
    text-transform: uppercase;
    font: {
      family: $font-family--body;
      size: rem-calc(16);
      style: normal;
    }
  }
}

  blockquote {
    margin: 0;
    font-size: rem-calc(18);
    font-style: italic;

    @include from(pt) {
      font-size: rem-calc(24);
    }

    p {
      line-height: 1.5;
    }
  }
