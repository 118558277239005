//
// Tiles
// --------------------------------------------------

.two-tile {
  overflow: hidden;

  @include from(pt) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .add-padding {
    overflow: hidden;
    // @include content-vertical-padding;
  }

  header {
    margin-bottom: rem-calc(20);

    h2,
    h3 {
      margin-bottom: 0;
    }

    h3 {
      color: palette(brand, accent);
    }
  }

  &__image {
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
    height: 60vw;
    max-height: 60vh;

    @include from(pt) {
      display: flex;
      align-items: center;
      margin-bottom:  0;
      padding-bottom: 0;
      min-height: 40vw;
      height: auto;
      max-height: none;
      width: 50%;

      .bg-img {
        background-position: right center !important;
      }

      img,
      picture {
        display: block;
        width: 100%;
      }
    }
  }

  &__content {
    @include from(pt) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
    }
  }

  &__inner {
    @include add-section-vertical-spacing;
    @include add-horizontal-spacing;
    @include add-max-width;
    margin-right: auto;
    margin-left:  auto;

    @include from(pt) {
      margin-right: 0;
      margin-left:  0;
    }
  }

  &--left &__image {
    order: 2;

    @include from(pt) {
      .bg-img {
        background-position: left center !important;
      }
    }
  }
}
