//
// Grid Mixins
// --------------------------------------------------
// @TODO: Add component spacing mixins?

$debug-grid: false !default;

@mixin grid-background {
  @if $debug-grid == true {
    @include background-grid;
  }
}

@mixin _add-horizontal-spacing-right($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  #{$type}-right: $horizontal-spacing--mobile;

  @include from(pt) {
    #{$type}-right: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-right: $horizontal-spacing--desk;
  }
}

@mixin _add-horizontal-spacing-left($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  #{$type}-left: $horizontal-spacing--mobile;

  @include from(pt) {
    #{$type}-left: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-left: $horizontal-spacing--desk;
  }
}

@mixin add-horizontal-spacing($horizontal-spacing: $spacing-unit--horz, $type: 'padding', $direction: 'both') {
  @if $direction == 'right' or $direction == 'both' {
    @include _add-horizontal-spacing-right($horizontal-spacing, $type);
  }
  @if $direction == 'left' or $direction == 'both' {
    @include _add-horizontal-spacing-left($horizontal-spacing, $type);
  }
}

@mixin negate-horizontal-spacing($horizontal-spacing: $spacing-unit--horz, $direction: 'both') {
  @if $direction == 'right' or $direction == 'both' {
    @include _add-horizontal-spacing-right(-$horizontal-spacing, 'margin');
  }
  @if $direction == 'left' or $direction == 'both' {
    @include _add-horizontal-spacing-left(-$horizontal-spacing, 'margin');
  }
}

@mixin add-max-width($max-width: $max-width--base, $horizontal-spacing: $spacing-unit--horz) {
  @if $horizontal-spacing == false {
    max-width: rem-calc($max-width);
  } @else {
    $horizontal-spacing--desk:   $horizontal-spacing;
    $horizontal-spacing--tab:    $horizontal-spacing * $spacing-mod--horz--tab;
    $horizontal-spacing--mobile: $horizontal-spacing * $spacing-mod--horz--mobile;

    max-width: rem-calc(($horizontal-spacing--mobile * 2) + $max-width);

    @include from(pt) {
      max-width: rem-calc(($horizontal-spacing--tab * 2) + $max-width);
    }

    @include from(d) {
      max-width: rem-calc(($horizontal-spacing--desk * 2) + $max-width);
    }
  }
}

@mixin kill-desktop-spacing($type: 'padding', $direction: 'both') {
  @include from(d) {
    @if $direction == 'right' or $direction == 'both' {
      #{$type}-right: 0;
    }
    @if $direction == 'left' or $direction == 'both' {
      #{$type}-left: 0;
    }
  }
}

@mixin _add-vertical-spacing-top($type, $vertical-spacing) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--desk:   $vertical-spacing;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-top: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-top: rem-calc($spacing-unit--vert--tab);
  }

  @include from(d) {
    #{$type}-top: rem-calc($spacing-unit--vert--desk);
  }
}

@mixin _add-vertical-spacing-bottom($type, $vertical-spacing) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--desk:   $vertical-spacing;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-bottom: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--tab);
  }

  @include from(d) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--desk);
  }
}

@mixin add-section-vertical-spacing($type: 'padding', $vertical-spacing: $spacing-unit--vert, $direction: 'both') {
  @if $direction == 'top' or $direction == 'both' {
    @include _add-vertical-spacing-top($type, $vertical-spacing);
  }
  @if $direction == 'bottom' or $direction == 'both' {
    @include _add-vertical-spacing-bottom($type, $vertical-spacing);
  }
}

@mixin remove-section-vertical-spacing-top($vertical-spacing: $spacing-unit--vert) {
  //setup padding variables if one was provided

  $vertical-spacing: $vertical-spacing * -1;

  @include _add-vertical-spacing-top('margin', $vertical-spacing);

  // @TODO: Update to use vars
  padding-top: rem-calc(30);

  @include from(pt) {
    padding-top: rem-calc(40);
  }
}

@mixin add-component-spacing($type: 'padding', $vertical-spacing: $spacing-unit--component) {
  $spacing-unit--component--desk:   $vertical-spacing;
  $spacing-unit--component--tab:    $vertical-spacing * $spacing-mod--component--tab;
  $spacing-unit--component--mobile: $vertical-spacing * $spacing-mod--component--mobile;


  #{$type}: rem-calc($spacing-unit--component--mobile);

  @include from(pt) {
    #{$type}: rem-calc($spacing-unit--component--tab);
  }

  @include from(d) {
    #{$type}: rem-calc($spacing-unit--component--desk);
  }
}

.cf {
  @include clearfix;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
