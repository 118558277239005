.calendar {
  &__inner {
    @include add-max-width(1100);
    @include add-horizontal-spacing;
    margin-left:  auto;
    margin-right: auto;
  }

  .fc-view {
    background-color: palette(white);
  }

  .fc-widget-header {
    background-color: palette(brand);
    border-color: palette(brand);
  }

    .fc-day-header {
      border-color: palette(brand);
      color: palette(white);
      padding: rem-calc(5 0);
    }

  .fc-basic-view .fc-day-number,
  .fc-basic-view .fc-week-number {
    padding: 3px 5px;
  }

  .fc-basic-view .fc-body .fc-row {
    min-height: rem-calc(100);
  }

  .fc-day-grid-event {
    margin: 3px 5px 0;
  }

  .fc-unthemed td.fc-today {
    background: #e4f5ff;
  }

  a.fc-more {
    margin: 1px 5px;
  }
}