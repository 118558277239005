//
// Lists
// --------------------------------------------------

%list-link-style {
  font-family: $font-family--heading;
  font-weight: 700;
  color: $color--text;
  position: relative;
  display: block;

  &:hover {
    color: $color--hover;
  }
}

%list-container {
  border-top: 1px solid $color--border;
  overflow: hidden;
  padding: rem-calc(15 0);

  &:last-child {
    border-bottom: 1px solid $color--border;
  }
}

.list {
  &__inner {
    @include pie-clearfix;
    margin:  0;
    padding: 0;
  }

  // @TODO: Investigate why this isn't applying
  @include from(lp) {
    &.has-2-columns &__inner {
      column-count: 2;

      .list__item {

        // Half-way border hack
        @for $i from 3 through 30 {
          &:nth-child(#{$i}):nth-last-child(#{$i - 2}),
          &:nth-child(#{$i}):nth-last-child(#{$i - 1}) {
            border-top: 1px solid $color--border;
          }
        }
      }
    }
  }

  &__item {
    border-bottom: 1px solid $color--border;
    break-inside: avoid;
    position: relative;
    margin:  0;
    padding: rem-calc(7 0);
    list-style-type: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;

    &:first-child {
      border-top: 1px solid $color--border;
    }

    &.has-icon {
      padding: rem-calc(7 0 7 30);
    }

    &__content {
      width: 100%;
    }

    &.has-label &__content {
      text-align: right;
      margin-left: auto;
      width: auto;

      @include to(lp) {
        font-size: rem-calc(12);
      }
    }
  }

  &__item--link {
    padding: 0;

    a {
      display: block;
      outline: 0;
      font-weight: 700;
      padding: rem-calc(7 35 7 0);
      width: 100%;

      &::after {
        @extend %right;
        @include icon;
        color: palette(brand);
        display: inline-block;
        font-size: rem-calc(24);
        margin-top: 2px;
        padding: 0;
        position: absolute;
        top:  50%;
        right: 0;
        transform: translate(0, -50%);
        transition: color .3s ease-out;
      }

      &:hover {
        &::after {
          color: palette(brand, secondary);
        }
      }
    }
  }

  &__icon {
    display: block;
    color: palette(brand);
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 5px;
    font-size: 20px;
    transform: translate(0, -50%);
    height: auto;
    width: 13px;

    i {
      display: block;
      height: 20px;

      &::before {
        vertical-align: top;
      }
    }
  }
}
