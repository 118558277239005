.logo-list {
  .heading {
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    padding: 0;

    &.logo-list__inner {
      margin-bottom: rem-calc(-20);
    }
  }

    &__item {
      flex: 0 0 auto;
      line-height: 0;
      padding: rem-calc(20);
      max-width: 70%;

      &__logo {
        &.lazyload {
          opacity: 0;
          transform: translateY(5%);
        }

        &.lazyloaded {
          opacity: 1;
          transform: translateY(0%);
          transition: opacity .3s ease-out, transform .4s ease-out;
        }
      }
    }

  @include from(lp) {
    &__item {
      // flex: 0 0 30%;
      max-width: 30%;
    }
  }

  @include from(pt) {
    &__item {
      padding: rem-calc(20 30);
    }
  }
}
