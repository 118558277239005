//
// Testimonials
// --------------------------------------------------

.testimonials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.testimonial {
  height: auto;
  min-height: 100vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include from(lp) {
    min-height: 50vw;
  }

  @include from(ld) {
    min-height: 40vw;
  }

  &__image {
    position: absolute;
    right:  0;
    bottom: 0;
    height: 100%;
    width:  140%;
    margin-right: -40%;
    z-index: 1;

    img {
      @include object-fit(contain, right);
      height: 100%;
      width:  100%;
    }

    @include from(lp) {
      margin-right: -15%;
      width: 115%;
    }

    @include from(pt) {
      margin-right: -10%;
      width: 110%;
    }

    @include from(d) {
      margin-right: 0;
      width: 100%;
    }

    .bg-img {
      background-position: bottom right -160px !important;
      background-size: cover !important;

      @include from(lp) {
        background-position: bottom right -200px !important;
      }

      @include from(pt) {
        background-position: bottom right -100px !important;
        background-size: contain !important;
      }

      @include from(d) {
        background-position: bottom right !important;
      }
    }
  }

  &__inner {
    @include add-section-vertical-spacing;
    @include add-horizontal-spacing;
    max-width: rem-calc(240);
    position: relative;
    z-index: 2;

    @include from(lp) {
      max-width: rem-calc(360);
    }

    @include from(pt) {
      max-width: rem-calc(460);
    }

    @include from(lt) {
      @include add-max-width(500);
    }
  }

  &__intro {
    // @include responsive-font(7vw, 48px, false, #{rem-calc(94)});
    font-family: $font-family--special;
    font-size: rem-calc(32);
    line-height: 1;

    @include from(lp) {
      font-size: rem-calc(46);
    }

    @include from(pt) {
      font-size: rem-calc(56);
    }

    @include from(lt) {
      font-size: rem-calc(80);
    }

    @include from(d) {
      font-size: rem-calc(94);
    }
  }

  &__quote {
    margin-bottom: rem-calc(20);
    max-width: rem-calc(300);

    @include from(pt) {
      max-width: rem-calc(420);
    }
  }

  &__author {
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    line-height: 1.3;
    text-transform: uppercase;
    font-size: rem-calc($font-size--h3);
    letter-spacing: $letter-spacing--small;
  }

  &__nav {
    cursor: pointer;
    height: rem-calc(17);
    width:  rem-calc(27);
    position: absolute;
    bottom: rem-calc(20);
    z-index: 3;

    svg {
      fill: $_color-base-grey;
      transition: fill .3s ease-out;
    }

    &--next {
      right: rem-calc(20);
      transform: scaleX(-1);
    }

    &--prev {
      left: rem-calc(20);
    }

    &:hover {
      svg {
        fill: palette(brand, secondary);
      }
    }

    @include from(pt) {
      bottom: rem-calc(45);

      &--next {
        right: rem-calc(45);
      }

      &--prev {
        left: rem-calc(45);
      }
    }
  }
}