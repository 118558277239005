//
// Navigation
// --------------------------------------------------

$nav-link-color: $color--text;
$nav-link-spacing: rem-calc(15);
$nav-font-size: rem-calc(17);
$nav-font-weight: $font-weight--regular;



.l-region--navigation {
  .icon {
    display: block;
    line-height: 0;
    height: auto;
    width: rem-calc(17);

    &--search {
      stroke: palette(brand, dark);
    }

    &--close {
      fill: palette(brand);
    }
  }

  @include from(sd) {
    > * {
      padding: rem-calc(15 30);
    }

    > .navigation__primary {
      padding-bottom: 30px;
    }

    .navigation {
      &__secondary {
        border-bottom: $color--border 1px solid;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > ul {
          display: flex;
          align-items: center;
          line-height: 1;
          padding: 0 $nav-link-spacing;
        }
      }

      &__meta {
        display: flex;
        justify-content: space-between;

        .button {
          margin-left: rem-calc(5);
        }
      }

      &__primary {
        > ul {
          display: flex;
          align-items: center;
          flex-flow: row nowrap;
          justify-content: center;
        }
      }
    }
  }
}

.nav--desktop {
  @include pie-clearfix;
  list-style-type: none;
  margin:  0;
  padding: 0;

  li {
    margin:  0;
    padding: 0;
  }

  > li {
    display: inline-block;
    // position: relative;

    &.menu-item-has-children {
      > a {
        &::before {
          @extend %icon;
          @extend %angle-down;
          color: $color--text;
          font-size: rem-calc(20);
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: rem-calc(-10);
          transition: all .5s ease;
          will-change: transform;
          height: rem-calc(20);
          width:  rem-calc(20);
        }

        &::after {
          @include triangle(30px 15px, palette(white), up);
          content: '';
          left: 50%;
          margin-top: -14px;
          opacity: 0;
          filter: drop-shadow(0px -4px 2px rgba(0,0,0,.15));
          position: absolute;
          top: 100%;
          transform: translateX(-50%) translateY(200%);
          transition: transform .4s ease, opacity .2s ease;
          visibility: hidden;
          z-index: 11;
        }
      }
      &.hover-active {
        > a {
          &::before {
            opacity: 0;
            transform: translateY(-30%) rotateZ(180deg);
          }

          &::after {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            visibility: visible;
          }
        }
      }
    }

    > .sub-menu {
      background-color: palette(white);
      border-radius: 3px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, .5);
      font-weight: $font-weight--light;
      position: absolute;
      top: 100%;
      left: 50%;
      list-style: none;
      opacity: 0;
      margin: -30px 0 0;
      padding: rem-calc(30 0);
      transform: translate(-50%, 20px);
      transition: transform .4s ease, opacity .2s ease, visibility .5s ease;
      // min-height: rem-calc(300);
      min-height: rem-calc(356);
      max-width: rem-calc(1100);
      width: 100%;
      visibility: hidden;
      z-index: 10;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &::before {
        border-radius: 0 3px 3px 0;
        content: "";
        background: url(../images/megamenu--weights.jpg);
        background-size: cover;
        position: absolute;
        top: rem-calc(10);
        left: rem-calc(380);
        height: calc(100% - #{rem-calc(20)});
        width: calc(100% - #{rem-calc(390)});
      }

      &::after {
        content: '';
        background: rgba(2, 114, 173, .7);
        pointer-events: none;
        position: absolute;
        top: rem-calc(10);
        left: rem-calc(380);
        height: calc(100% - #{rem-calc(20)});
        width: calc(100% - #{rem-calc(390)});
      }

      > li {
        padding: rem-calc(0 50);
        width: rem-calc(380);

        &.menu-item-has-children {
          &:hover {
            > a {
              color: palette(brand, secondary);

              &::after {
                color: palette(brand, secondary);
              }
            }

            > .sub-menu {
              opacity: 1;
              visibility: visible;
            }
          }

          > a {
            &::after {
              @extend %right;
              @include icon;
              color: palette(brand);
              display: inline-block;
              font-size: rem-calc(24);
              margin-top: 2px;
              padding: 0;
              position: absolute;
              top:  50%;
              right: 0;
              transform: translate(0, -50%);
              transition: color .3s ease-out;
            }
          }
        }

        &:last-child {
          margin-bottom: 1px;
        }
      }

      .sub-menu {
        transition: transform .4s ease, opacity .2s ease, visibility .5s ease;
        top:   0;
        left: rem-calc(380);
        list-style: none;
        height: 100%;
        padding: rem-calc(30 0 30 40);
        transform: translate(0);
        width: calc(100% - #{rem-calc(380)});
        opacity: 0;
        visibility: hidden;
        z-index: 5;

        display: flex;
        flex-flow: column wrap;

        > li {
          padding-right: rem-calc(40);
          width: 50%;

          a {
            border-color: transparent;
            color: palette(white);
            font-size: rem-calc(15);
            font-weight: $font-weight--bold;
            letter-spacing: $letter-spacing--small;
            line-height: #{rem-calc(17) * 1.6};
            text-transform: uppercase;
            transition: opacity ease-out .3s;
            opacity: 1;

            &:hover {
              opacity: .6;
            }
          }
        }
      }
    }
  }

  a {
    color: palette(brand, dark);
    display: block;
    font-size: $nav-font-size;
    font-weight: $nav-font-weight;
    position: relative;
    text-align: center;
    padding: 0 ($nav-link-spacing * .5);

    @include from(sd) {
      padding: 0 ($nav-link-spacing * .8);
    }

    @include from(d) {
      padding: 0 $nav-link-spacing;
    }
  }

  .navigation__primary & > li {
    > a {
      font-size: rem-calc(21);
      padding: rem-calc(15 30);

      @include at(sd) {
        font-size: rem-calc(18);
      }
    }
  }

  // Other Levels
  .sub-menu {
    position: absolute;

    a {
      border-bottom: 1px solid $color--border;
      padding-top:     7px;
      padding-right:   0;
      padding-bottom:  7px;
      padding-left:    0;
      text-align: left;
    }

    li {
      &:first-of-type {
        a {
          border-top: 1px solid $color--border;
        }
      }
    }
  }

  .hover-active {
    .sub-menu {
      opacity: 1;
      transform: translate(-50%, 0);
      visibility: visible;
    }
  }
}
