//
// Content Columns
// --------------------------------------------------

.content-columns {
  &__container {
    @include add-horizontal-spacing;
    @include add-max-width(1140);
    margin-left:  auto;
    margin-right: auto;

    @include from(pt) {
      display: flex;
      justify-content: center;
    }
  }

  .heading {
    @include add-horizontal-spacing;
    margin-bottom: rem-calc(50);
    text-align: center;
  }
}

.content-column {
  margin-right: auto;
  margin-bottom: rem-calc(40);
  margin-left: auto;
  max-width: rem-calc($max-width--narrow + 40);
  width: 100%;

  h3 {

  }

  h4 {
    color: palette(brand, accent);
    font-size: rem-calc(21);
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;
  }

  @include from(pt) {
    margin-bottom: 0;
  }

  @include from(lt) {
    max-width: rem-calc($max-width--narrow + 80);
  }

  &__inner {
    margin-left:    auto;
    margin-right:   auto;
    padding-right:  rem-calc(20);
    padding-bottom: rem-calc(30);
    padding-left:   rem-calc(20);
    text-align: center;

    @include from(lt) {
      padding-right: rem-calc(40);
      padding-bottom: 0;
      padding-left:  rem-calc(40);
    }
  }

    &__image  {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right:  auto;
      margin-bottom: rem-calc(40);
      margin-left:   auto;

      .content-column.has-icon & {
        width: rem-calc(92);
      }

      img {
        display: block;
      }

      &.svg {
        padding: rem-calc(30);
      }

      svg {
        height: rem-calc(100);
        width:  rem-calc(100);
      }
    }

    &__content {
      margin-bottom: rem-calc(30);
    }

  .content-columns.has-2 & {
    @include from(pt) {
      border: 0;
    }
  }

  .content-columns.has-3 & {
    @include from(pt) {
      &:nth-child(3n) {
        border-right: 0;
      }
    }
  }
}
