.program {
  width: 100%;

  &:nth-of-type(2n-1) {
    background-color: palette(white, dark);
  }

  &__inner {
    @include add-horizontal-spacing;
    @include add-section-vertical-spacing;
    @include add-max-width(860);
    margin-left:  auto;
    margin-right: auto;
  }

  &__intensity {
    margin-bottom: rem-calc(30);
    width: 200px;

    &__title {
      color: palette(brand, dark);
      font-size: rem-calc(15);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--medium;
      text-transform: uppercase;
      margin-bottom: rem-calc(5);
    }

    &__meter {
      background: linear-gradient(to right, palette(brand), palette(brand, secondary));
      border-radius: 15px;
      padding: rem-calc(0 10);
      height: rem-calc(15);

      &__inner {
        height: 100%;
        position: relative;
      }

      &__indicator {
        background-color: palette(white);
        border: #b1b1b1 2px solid;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        height: rem-calc(21);
        width:  rem-calc(21);
      }
    }

    &__labels {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      color: #c6c6c6;
      font-size: rem-calc(15);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--medium;
      text-transform: uppercase;
    }
  }

  &__description {
    margin-bottom: rem-calc(60);
    max-width: rem-calc($max-width--base);
  }

  &__classes {
    @include from(lp) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__class {
    margin-bottom: rem-calc(20);

    &:last-child() {
      margin-bottom: 0;
    }

    @include from(lp) {
      flex: 0 0 45%;
      margin-right: 10%;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @include from(lt) {
      flex: 0 0 30%;
      margin-right: 5%;

      &:nth-child(2n) {
        margin-right: 5%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-last-child(3) {
        margin-bottom: 0;
      }
    }
  }
}

.class {
  font-size: rem-calc(17);

  > * {
    border-bottom: $color--border 1px solid;
    padding: rem-calc(10 0);
  }

  &__weekday {
    margin: 0;
  }

  &__location {
    a {
      color: $color--text;
      font-weight: $font-weight--body;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__price {
    padding: rem-calc(10 0);

    &--early {
      font-size: rem-calc(11);
    }
  }

  &__details {
    font-size: rem-calc(14);
  }

  &__register {
    border-bottom: 0;
  }

  &__flag {
    margin-left: rem-calc(10);

    &--drop-in {
      @include hide-text;
      background: url(../images/icons/icon--drop-in.svg) 0 0 no-repeat;
      height: rem-calc(25);
      width: rem-calc(120);
    }

    &--sample {
      @include hide-text;
      background: url(../images/icons/icon--sample-class.svg) 0 0 no-repeat;
      height: rem-calc(18);
      width:  rem-calc(85);
    }
  }
}