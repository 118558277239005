//
// Accordion
// --------------------------------------------------

.accordion {
  width: 100%;

  &__handler {
    border-bottom: 1px solid $color--border;
  }

  &__container {
    border-top: 1px solid $color--border;
    padding: 0;

    h3 {
      position: relative;

      strong {
        width: 143px;

        @include from(pt) {
          display: inline-block;
          width: 143px;
        }
      }

      &::before {
        @extend %icon;
        @extend %down;
        position: absolute;
        right: 10px;
        width: 21px;
        height: 21px;
        transition: transform .3s linear;
      }

      &.is-active {
        &::before {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  &__header {
    @extend %list-link-style;
    cursor: pointer;
    margin:  0;
    padding: rem-calc(15 30 15 0);
    position: relative;
    line-height: 1.5;
    text-transform: none;
    font-family: $font-family--body;
    font-size: rem-calc(17);
    font-weight: 400;

    .icon {
      fill: #82888a;
      height: 13px;
      width:  14px;
      position: absolute;
      right: 10px;
      top:   50%;
      transform: translateY(-50%) rotate(180deg);
      transition: transform .3s $ease-in-out-quad;
    }

    &::before {
      @extend %plus;
      @include icon;
      backface-visibility: hidden;
      color: palette(brand);
      display: block;
      height: auto;
      width:  auto;
      position: absolute;
      right: 0;
      top:  50%;
      margin-top: rem-calc(-10);
      line-height: 1;
      font-size: rem-calc(24);
      transform: rotate(0deg);
      transition: all .3s linear;
    }

    &.is-active {
      &::before {
        transform: rotate(135deg) translate3d(-2px, 4px, 0);
      }
    }

    em {
      position: absolute;
      top: rem-calc(34);
      font-size: rem-calc(16);
    }
  }

  &__content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: rem-calc(20 10 40);
    text-align: left;
    transform: scale(.9);
    transition: transform .3s ease;
    will-change: transform;

    @include from(lp) {
      padding: rem-calc(20 30 40);
    }

    p {
      margin-top: 0;
    }

    &.open-content {
      transform: scale(1);
    }
  }
}
