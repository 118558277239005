//
// Header
// --------------------------------------------------

$header-height-default: 100px;
$header-height-mobile:  100px;
$header-height-float:      .7;
$header-background:     palette(brand, dark);

$logo-width-default:    rem-calc(130);
$logo-width-float:      rem-calc(110);
$logo-width-mobile:     rem-calc(110);



.hamburger {
  outline: 0;
}

.float-wrapper,
.l-region--header {
  height: rem-calc($header-height-mobile);
  width:  100%;

  @include from(sd) {
    height: rem-calc($header-height-default);
  }

  // option
  @if $nav-in-hero == true {
    position: absolute;
    top:  0;
    left: 0;
    width: 100%;
  }
}

.nav__toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top:   0;
  right: 0;
  z-index: 1250;

  .hamburger__container {
    background-color: palette(white);
    border-radius: 100%;
    display: inline-block;
    line-height: 0;
    padding: rem-calc(13 10);
    transition: background-color .3s cubic-bezier(.22,.61,.36,1);
  }

  .hamburger-inner {
    &, &::before, &::after {
      transition: background-color .3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &--mobile {
    @include from(sd) {
      display: none;
    }
  }

  &--desktop {
    margin-left: rem-calc(30);

    .hamburger__label {
      color: palette(white);
      font-size: rem-calc(15);
      font-weight: $font-weight--bold;
      margin-right: 5px;
      text-transform: uppercase;
      transition: color .3s ease-in-out;
    }

    &:hover {
      .hamburger {
        opacity: 1;

        &__container {
          background-color: palette(brand);
        }

        &__label {
          // color: palette(brand);
        }

        &-inner {
          &, &::before, &::after {
            background-color: palette(white);
          }
        }
      }
    }

    @include to(lt) {
      display: none;
    }
  }
}

.l-region--header {
  position: relative;
  padding: rem-calc(20);
  transition: background-color .3s $ease-out-cubic,
              height .2s $ease-out-cubic;
  z-index: 100;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .hamburger-box {
    vertical-align: middle;
  }

  // option
  @if $nav-in-hero == true {
    background-color: transparent;
  } @else {
    background-color: $header-background;
  }

  .header-float & {
    background-color: $header-background;
    position: fixed;
    // transform: translateY(-100%);
    // transition: transform 0s linear;
    height: rem-calc($header-height-mobile * $header-height-float);

    .logo {
      width: $logo-width-mobile;
    }

    .l-region--navigation {
      .logo {
        width: $logo-width-default;
      }
    }

    @include from(sd) {
      height: rem-calc($header-height-default * $header-height-float);
    }

    // &.header-up,
    // &.header-down {
    //   transition: transform .4s $ease-out-cubic;
    // }

    // &.header-up {
    //   transform: translateY(-100%);
    // }

    // &.header-down {
    //   transform: translateY(0%);
    // }
  }

  .header-float.admin-bar & {
    @include from(pt) {
      top: 46px;
    }
    @include from(lt) {
      top: 32px;
    }
  }

  .logo {
    display: block;
    max-height: 100%;
    transition: width .2s $ease-out-cubic;
    width: $logo-width-mobile;

    @include from(d) {
      width: $logo-width-default;
    }

    svg {
      fill: palette(white);
      height: auto;
      max-width: 100%;
    }
  }

  .desk {
    display: none;
  }

  .meta,
  .l-region--navigation {
    display: none;

    @include from(sd) {
      display: block;
    }
  }

  @include from(sd) {
    .l-region--navigation {
      background: palette(white);
      border-bottom: $color--border 1px solid;
      flex: 1 0 auto;
      position: absolute;
      top:  0;
      left: 0;
      height: auto;
      transition: transform .4s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translate(0, -100%);
      width: 100%;
      z-index: 1300;

      &.is-expanded {
        transform: translate(0, 0);
      }

      > ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    .desk {
      display: block;
    }

    .meta {
      flex: 1 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      .icon-search {
        height: rem-calc(20);
        width:  rem-calc(20);
      }

      .button {
        margin-left: rem-calc(5);
      }
    }
  }

  @include from(ld) {
    .search {
      margin-right: rem-calc(40);
    }
  }
}
