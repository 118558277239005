.pricing-tables {
  .component__content {
    @include add-horizontal-spacing;
    @include add-max-width(1028);
    margin-left:  auto;
    margin-right: auto;
  }

  .heading {
    text-align: center;
  }

  .pricing-tables__container {
    display: flex;
    flex-flow: column nowrap;

    @include from(lt) {
      flex-flow: row wrap;
      justify-content: center;
      margin: rem-calc(0 -15);
    }
  }

  &.background-color--dark {
    .pricing-table {
      &__content {
        background-color: palette(white);
      }
    }
  }
}

.pricing-table {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;
  margin-bottom: rem-calc(30);

  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    // display: flex;
    padding-left:  0;
    padding-right: 0;
    // padding-bottom: rem-calc(40);
    max-width: rem-calc(420);
    width: 100%;
  }

  .pricing-tables.has-1 &__inner {
    max-width: rem-calc($max-width--base);
    padding: 0;
  }

  &__heading {
    background-color: palette(grey);
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem-calc(0 30);
    height: rem-calc(70);

    h3 {
      color: palette(white);
      font-size: rem-calc($font-size--p);
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__content {
    background: palette(white, dark);
    border-radius: 0 0 10px 10px;
    margin: 0;
    padding: rem-calc(20 30);
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
  }

  .list {
    &__item {
      &, &.has-label .list__item__content {
        font-size: 14px;
      }

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @include from(pt) {
    margin-bottom: 0;
    min-width: rem-calc(300);

    &__inner {
      padding-bottom: rem-calc(30);
    }

    .list {
      &__item {
        &, &.has-label .list__item__content {
          font-size: rem-calc($font-size--p);
        }
      }
    }
  }

  @include from(lt) {
    .pricing-tables.has-2 & {
      flex: 0 0 percentage(1/2);
    }

    .pricing-tables.has-3 & {
      flex: 0 0 percentage(1/3);
    }

    &__inner {
      padding-left:   rem-calc(15);
      padding-right:  rem-calc(15);
      padding-bottom: 0;
    }

    .list {
      &__item {
        &, &.has-label .list__item__content {
          font-size: rem-calc(16);
        }
      }
    }
  }

  @include from(sd) {
    .list {
      &__item {
        &, &.has-label .list__item__content {
          font-size: rem-calc($font-size--p);
        }
      }
    }
  }

  &:first-child {
    .pricing-table__heading {
      background: palette(brand);
    }
  }

  &:nth-child(2),
  &:first-child:last-child {
    .pricing-table__heading {
      background: palette(brand, accent);
    }
  }

  &:nth-child(3) {
    .pricing-table__heading {
      background: palette(brand, dark);
    }
  }

  &.pricing-table.color--light {
    .pricing-table__heading {
      background: palette(brand, light);
    }
  }

  &.pricing-table.color--medium {
    .pricing-table__heading {
      background: palette(brand);
    }
  }

  &.pricing-table.color--dark {
    .pricing-table__heading {
      background: palette(brand, accent);
    }
  }
}