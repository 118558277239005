//
// Typography
// --------------------------------------------------

html {
  color: $color--text;
  line-height: $line-height--base;
  font-family: $font-family--body;
  font-size: 16px;
  font-weight: $font-weight--body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include from(pt) {
    font-size: $font-size--base;
  }

  @include from(vld) {
    font-size: 24px;
  }

  &.lt-ie9 {
    font-size: $font-size--base;
  }
}

::selection {
  background-color: palette(brand, light);
  color: palette(white);
}

// Links
a {
  color: palette(brand);
  font-weight: $font-weight--bold;
  text-decoration: none;
  transition: color .3s ease-out;

  .hero & {
    color: palette(brand, light);
  }

  &:hover {
    color: palette(brand, secondary);
  }
}


// Headings
h1, h2, h3, h4, h5, h6, p, ul, ol, span {
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig';
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: rem-calc(20);

  &:last-child {
    margin-bottom: 0;
  }
}

h1, .alpha,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
  color: palette(brand, dark);
  font-family: $font-family--heading;
  font-weight: $font-weight--heading;
  line-height: 1.3;
  text-transform: uppercase;

  margin-top: rem-calc(40);

  &:first-child,
  h1 + &,
  h2 + &,
  h3 + &,
  h4 + &,
  h5 + &,
  h6 + & {
    margin-top: 0;
  }

  &.heading {
    margin-bottom: rem-calc(30);
  }
}

h1, .alpha {
  color: palette(brand);
  font-family: $font-family--special;
  font-size: rem-calc($font-size--h1 * .6);

  .hero--home & {
    font-size: 54px;
  }

  @include from(lp) {
    .hero--home & {
      font-size: 46px;
    }
  }

  @include from(pt) {
    font-size: rem-calc($font-size--h1);

    .hero--home & {
      font-size: rem-calc($font-size--h1--home * .7);
    }
  }

  @include from(lt) {
    .hero--home & {
      font-size: rem-calc($font-size--h1--home);
    }
  }

  &.heading {
    color: $color--text;
  }
}

h2, .beta {
  font-size: rem-calc($font-size--h2);
  line-height: 1.125em;


  @include to(lp) {
    font-size: rem-calc(28);
  }
}

h3, .gamma {
  font-size: rem-calc($font-size--h3);
  letter-spacing: $letter-spacing--small;
}

h4, .delta {
  font-size: rem-calc($font-size--h4);
  font-weight: $font-weight--regular;
  letter-spacing: $letter-spacing--small;
}

h5, .epsilon {
  color: palette(brand, accent);
  font-size: rem-calc($font-size--h5);
  text-transform: none;
}

h6, .zeta {
  color: palette(brand, accent);
  font-size: rem-calc($font-size--h6);
}

hr {
  border: 0;
  height: 1px;
  margin: rem-calc(25 0);
}



// Paragraphs
p,
%p {
  font-size: rem-calc($font-size--p);
  word-break: normal;
  word-wrap: normal;
}

ul {
  font-size: rem-calc($font-size--p);
  padding-left: 0;

  p & li,
  .accordion & li,
  .standard-text__content > & li {
    list-style-type: none;
    padding-left: 23px;
    position: relative;

    &:not(:first-child) {
      margin-top: 1em;
    }

    &::before {
      content: url(../images/icons/icon--check.svg);
      display: block;
      margin-right: 10px;
      position: absolute;
      top: 0;
      left: 4px;
      width: 13px;
    }
  }
}

ol {
  font-size: rem-calc($font-size--p);

  li {
    margin-bottom: rem-calc(10);
  }
}

strong {
  font-weight: 700;
}

pre {
  font-size: rem-calc(11);
}

address {
  font-style: normal;
}



// Marks
mark,
.marker {
  background: palette(brand);
  color: #fff;
  border-radius: rem-calc(10);
  padding: .1em .5em;
  font-size: rem-calc(12);
  text-transform: capitalize;
}

em{
	color: inherit !important;
}