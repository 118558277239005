//
// Banner
// --------------------------------------------------

.banner {
  min-height: 30vw;

  &__image {
    position: relative;
    padding-top: 65%;

    @include from(lt) {
      padding-top: 33%;
    }

    .banner.has-text & {
      position: absolute;
      top:  0;
      left: 0;
      padding-top: 0;
      height: 100%;
      width:  100%;
    }
  }

  &.has-overlay {
    .banner__image {
      &::after {
        content: '';
        // background: rgba(2, 114, 173, .7);
        background: palette(brand, accent);
        opacity: .8;
        pointer-events: none;
        position: absolute;
        top:  0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &-orange {
      .banner__image {
        &::after {
          background: palette(brand, secondary);
        }
      }
    }
  }

  &__content {
    @include add-horizontal-spacing;
    @include add-max-width($max-width--narrow);
    margin-right:   auto;
    margin-left:    auto;

    h2 {
      text-align: center;
    }

    p {
      margin-bottom: rem-calc(20);

      &:last-child {
        margin-bottom: 0;
      }
    }

    background-color: transparent;
    position: relative;
    z-index: 1;

    h2, p {
      color: palette(white);
      text-align: center;
    }
  }

  &.text-color--dark &__content {
    h2, p {
      color: $color--text;
    }
  }

  &.has-overlay &__content {
    h2, p {
      color: palette(white) !important;
    }
  }

  &__buttons {
    margin-top: rem-calc(40);
    text-align: center;
  }

  &.has-text {
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
