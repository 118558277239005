//
// Search
// --------------------------------------------------

.search {
  &-form {
    &__search.search-field {
      background-color: palette(white, dark);
      border-radius: rem-calc(10 0 0 10);
      line-height: rem-calc(20);
      padding: rem-calc(10 20);
    }

    &__submit {
      @include button;
      background-color: palette(brand, accent);
      border-radius: rem-calc(0 10 10 0);
      padding-top:    rem-calc(10);
      padding-bottom: rem-calc(10);
      line-height: rem-calc(20);
      outline: none;

      &:hover {
        background-color: palette(brand);
      }
    }

    .input-group--search {
      display: flex;
      margin-bottom: rem-calc(40);
      max-width: rem-calc(540);
      margin-left: 2rem;
      margin-right: 2rem;
      position: relative;

      svg {
        pointer-events: none;
        stroke: #515f67;
        position: absolute;
        left: rem-calc(15);
        top: 50%;
        transform: translate(0, -50%);
        width: rem-calc(17);
      }

      input {
        padding-left: rem-calc(45);
      }
    }

    .input-group--filters {
      background-color: palette(white, dark);
      display: flex;
      flex-direction: column;
      padding: rem-calc(30 40);

      > div {
        margin-bottom: rem-calc(20);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

      &__intensity {
        &__options {
          display: flex;
          flex-wrap: wrap;
        }

        &__option {
          flex: 0 0 50%;
        }
      }

      &__day {
        &__options {
          display: flex;
          flex-wrap: wrap;
        }

        &__option {
          flex: 0 0 25%;
        }
      }

    h6 {
      margin-bottom: rem-calc(5);
    }

    label {
      font-size: rem-calc(15);
    }

    @include from(lp) {
      .input-group--filters {
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          margin-bottom: rem-calc(30);

          &:first-child {
            flex: 0 0 50%;
          }

          &:nth-child(2) {
            flex: 0 0 50%;
          }

          &:last-child {
            flex: 0 0 100%;
          }
        }
      }
    }

    @include from(pt) {
      .input-group--search {
        margin-left:  auto;
        margin-right: auto;
      }

      .input-group--filters {
        flex-wrap: nowrap;

        > div {
          margin: 0;

          &:first-child {
            border-right: $color--border 1px solid;
            flex: 0 0 28%;
            padding: rem-calc(0 30 0 0);
          }

          &:nth-child(2) {
            border-right: $color--border 1px solid;
            flex: 0 0 28%;
            padding: rem-calc(0 30);
          }

          &:last-child {
            flex: 0 0 44%;
            padding: rem-calc(0 0 0 30);
          }
        }
      }
    }

    @include from(lt) {
      .input-group--filters {
        border-radius: rem-calc(10);
        flex-direction: row;

        > div {
          &:first-child {
            padding: rem-calc(0 40 0 0);
          }

          &:nth-child(2) {
            padding: rem-calc(0 40);
          }

          &:last-child {
            padding: rem-calc(0 0 0 40);
          }
        }
      }
    }
  }

  &__form {
    @include add-section-vertical-spacing;
    margin-right: auto;
		margin-left:  auto;

		padding-top: 35vh !important;
    @include from(lt) {
      @include add-max-width(860);
      @include add-horizontal-spacing;
    }
  }

  &__heading {
    @include add-max-width(860);
    @include add-horizontal-spacing;
    margin: 0 auto rem-calc(40);
    transition: opacity .6s ease-in;

    &.search__heading {
      opacity: 0;
    }

    @include from(pt) {
      margin-top: rem-calc(-40);
    }

    .search-results &.search__heading,
    &.search__heading.is-visible {
      opacity: 1;
    }
  }

  &__results {
		display: flex !important;
		flex-wrap: wrap;
    .no-results {
      @include add-max-width(860);
      @include add-horizontal-spacing;
      margin-left:  auto;
      margin-right: auto;
    }
  }
}

.search-feature {
  @include clearfix;
  background: palette(white);
  display: none;
  position: fixed;
  top: 0;
  opacity: 0;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  height: 100%;
  overflow: auto;
  width:  100%;
  z-index: 99999;

  &__brand {
    display: block;
    line-height: 0;
    margin: rem-calc(20 0 0 30);
    width: rem-calc(110);
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width:  100%;
  }

  .nocsstransform & {
    left: -200%;
  }

  &__form {
    position: relative;
    opacity: 0;
  }

  &__close {
    display: block;
    line-height: 0;
    position: fixed;
    fill: palette(brand);
    top: rem-calc(30);
    right: rem-calc(30);
    height: auto;
		width: rem-calc(17);
		z-index: 1;
  }
}

// Search results
main {
  .search {
    > header {
      text-align: center;
      padding-top:    rem-calc(35);
      padding-bottom: rem-calc(35);

      h3 {
        font-size: rem-calc(20);
      }
    }
  }
}
