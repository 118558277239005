//
// Blog
// --------------------------------------------------

body.blog,
body.archive {
  .main {
    background-color: palette(grey, lighter);
  }
}

.post-list {
  @include pie-clearfix;
  display: flex;
  flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  padding: rem-calc(20 10 0);
  text-align: left;
  max-width: rem-calc(1220);

  @include from(pt) {
    padding: rem-calc(20 10 0);
  }
}
